import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AnalyticsService } from '../@core/utils';
import { BaseResponse } from '../models/response-modal';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  // apiRoot = 'http://175.41.176.252:1500/api/v1'
  //  apiRoot= 'https://backend.script.tv/api/v1'
  apiRoot = environment.api.apiRoot;
  constructor(
    private readonly _ApiService: ApiService,
    private readonly _http: HttpClient
  ) { }


  getUserIpInfo() {
    // const token = '8ac0a858fa014e'
    // let header = new HttpHeaders();
    // header = header.set('Content-Type', 'application/json');
    // header = header.set("Authorization", "Bearer " + token)    
    // return this._http.get(`https://ipinfo.io/json`, {headers: header}).toPromise();
    let promise = Promise.resolve({
      ip: 'dummyData',
      loc: 'dummyData,dummyData',
      country: 'dummyData'
    });
    return promise;
  }

  async getUserProfile(userId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`viewuserprofile?id=${userId}`, { headers: header })
  }


  async uploadUserProfile(data: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`edituserProfile/uploadFile`, data, { headers: header });
  }


  async createChannel(data: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`createChannel`, data, { headers: header });
  }
  async getChannelLists(userId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getChannelByUserId?userId=${userId}`, { headers: header });
  }
  async deleteChannel(id: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`deleteChannel`, id, { headers: header });
  }

  async getChannelDetailsByChannelID(channelId: any, screenName: string, isOwner = false, userId?: any): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getChannelByChannelId?channelId=${channelId}&isOwner=${isOwner}&userId=${userId}`, { headers: header })
  }



  async getCategory(pageNo = 1, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getCategory?pageNo=${pageNo}`, { headers: header });
  }

  async uploadVideo(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`videoUpload`, body, { headers: header });
  }

  async getVideoDetailsByVideoId(vdoId: any, userId = 0, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getVideoByVideoId?videoId=${vdoId}&userId=${userId}`, { headers: header })
  }
  async getActiveChannelOfUserId(userId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getActiveChannelOfUserId?userId=${userId}`, { headers: header })
  }

  async likeVideo(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`likeOrDisLikeVideo`, body, { headers: header })
  }
  async getVideoLike(videoId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getVideoLike?videoId=${videoId}`, { headers: header });
  }

  async getDisLikeVideo(videoId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getVideoDislike?videoId=${videoId}`, { headers: header });
  }

  async AddComment(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`addComment`, body, { headers: header });
  }

  async AddCommentReplay(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`addCommentRepy`, body, { headers: header })
  }

  async getVideoCommnetByVdoID(vdoId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getVideoComment?videoId=${vdoId}`, { headers: header })
  }

  async subscribeChannel(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`subscribeChannel`, body, { headers: header });
  }

  async getSubscribedChannelByUserId(userId = 0, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getSubscribedChannelByUserId?userId=${userId}`, { headers: header })
  }

  async setdefaultVideoUploadSetting(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`defaultVideoUploadSetting`, body, { headers: header });
  }
  async getDefaultVideoDataByChannelId(channelId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getDefaultVideoDataByChannelId?channelId=${channelId}`, { headers: header })
  }


  async getUserVideoHistory(userId: any, pageNo = 1, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getUserVideoHistory?userId=${userId}&pageNo=${pageNo}`, { headers: header })
  }


  async removeVideoHistory(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`deleteUserVideoHistory`, body, { headers: header });
  }

  async getDashBoardPageData(appUserId = 0, categoryId = 0, filter = 'ALL', screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getDashBoardPageData?appUserId=${appUserId}&categoryId=${categoryId}&filter=${filter}`, { headers: header })
  } script

  async getOtherSuggestedVideo(categoryId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getOtherSuggestedVideo?categoryId=${categoryId}`, { headers: header })
  }

  async getVideoRecommendedSearch(search = '', pageNo = 1, userId = 0, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getVideoRecommendedSearch?search=${search}&pageNo=${pageNo}&userId=${userId}`, { headers: header })
  }

  async getrecommendItem(appUserId = 0, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`recommendation?appUserId=${appUserId}`, { headers: header })
  }

  async getRelatedVideo(videoId: any, pageNo = 1, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getRelatedVideo?videoId=${videoId}&pageNo=${pageNo}`, { headers: header })
  }

  async getChannelRelatedVideo(categoryId: any, channelId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getChannelRelatedVideo?categoryId=${categoryId}&channelId=${channelId}`, { headers: header })
  }
  async getRecommendedSearch(search: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getRecommendedSearch?search=${search}`, { headers: header });
  }
  async getVideoByChannelId(channelId: any, isChannelOwner = false, pageNo = 1, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getVideoByChannelId?channelId=${channelId}&isChannelOwner=${isChannelOwner}&pageNo=${pageNo}`, { headers: header })
  }

  async createVideoStream(formData: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`createVideoStream`, formData, { headers: header });
  }

  async getScheduledStream(channelId: any, pageNo = 1, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getScheduledStreamByChannelId?channelId=${channelId}&pageNo=${pageNo}`, { headers: header })
  }
  async updateVideoStream(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`updateVideoStream`, body, { headers: header });
  }

  async deleteVideo(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`deleteVideo`, body, { headers: header });
  }

  async getStreamHistoryByChannelId(channelId: any, pageNo = 1, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getStreamHistoryByChannelId?channelId=${channelId}&pageNo=${pageNo}`, { headers: header })
  }


  async getChannelVideoByCategory(channelId: any, search = '', categoryId = 0, pageNo = 1, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(
      `getChannelVideoSearchByCategory?channelId=${channelId}&search=${search}&categoryId=${categoryId}&pageNo=${pageNo}`,
      { headers: header }
    )
  }

  async videoStreamChunks(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`saveVideoStreamChunks`, body, { headers: header })
  }

  getVideoStream(): Promise<any> {
    return this._ApiService.getRequest<any>(`getVideoStreamFilesFolder`);
  }

  async getNotification(userId: any, pageNo = 1, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getNotification?userId=${userId}&pageNo=${pageNo}`, { headers: header })

  }

  async deleteNotification(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`deleteNotification`, body, { headers: header });
  }

  async readNotification(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`readNotification`, body, { headers: header })
  }

  async getcountUnreadNotifications(userId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`countUnreadNotifications?userId=${userId}`, { headers: header });
  }

  async getCountry(screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getCountries`, { headers: header });
  }


  async getChannelSubscriberListByChannelId(channelId: any, pageNo = 1, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getChannelSubscriber?channelId=${channelId}&pageNo=${pageNo}`, { headers: header })
  }

  async createWallet(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`generateWalletId`, body, { headers: header });
  }

  async getWalletBalance(body: any, screenName: string): Promise<any> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<any>(`user/getAccount`, body, { apiRoot: this.apiRoot, headers: header })
  }
  async endStream(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`endVideoStream`, body, { headers: header });
  }

  async getUserByuserId(userId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`user?id=${userId}`, { headers: header })
  }
  async getChannelSubscriptionPage(channelId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getSubscriptionPage?channelId=${channelId}`, { headers: header })
  }

  async getSuggestedChannel(screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getSuggestedChannel`, { headers: header });
  }

  async getAllBadgesType(screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getAllBadgeTypes`, { headers: header })
  }
  async getCustomBadgesAndFlairs(channelId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getCustomBadgesAndFlairs?channelId=${channelId}`, { headers: header })
  }

  async uploadCustomBadge(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`uploadCustomBadge`, body, { headers: header });
  }

  async getFairBadges(channelId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(``, { headers: header })
  }



  async getStatusPageDetail(channelId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getStatusPageDetail?channelId=${channelId}`, { headers: header })
  }

  async getAllBadgeFlairTypes(screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getAllBadgeFlairTypes`, { headers: header });
  }

  async getAllEmoteTypes(screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getAllEmoteTypes`, { headers: header });
  }

  async uploadCustomBadgeFlair(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`uploadCustomBadgeFlair`, body, { headers: header })
  }

  async uploadCustomEmote(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`uploadCustomEmote`, body, { headers: header });
  }

  async deleteCustomBadge(id: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.deleteRequest(`deleteCustomBadge?id=${id}`, { headers: header })
  }

  async deleteCustomBadgeFlair(id: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.deleteRequest(`deleteCustomBadgeFlair?id=${id}`, { headers: header })
  }

  async deleteCustomEmote(id: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.deleteRequest<BaseResponse>(`deleteCustomEmote?id=${id}`, { headers: header });
  }
  async getSubscriptionTypeById(subsId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getSubscriptionTypeById?id=${subsId}`, { headers: header })
  }

  async purchage(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`purchaseSubscription`, body, { headers: header });
  }

  async getUserSubscription(userId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getUserSubscription?userId=${userId}`, { headers: header });
  }

  async activateUserSubscription(id: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.putRequest<BaseResponse>(`activateUserSubscription?id=${id}`, '', { headers: header });
  }
  async getActiveUsers(pageNo = 1, userName = '', screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getActiveUsers?userName=${userName}&pageNo=${pageNo}`, { headers: header });
  }

  async giftSubscription(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`giftSubscription`, body, { headers: header });
  }

  async getUsersWithOthersReferralCode(othersReferralCode: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getUsersWithOthersReferralCode?othersReferralCode=${othersReferralCode}`, { headers: header })
  }

  async sendReferralCodeOnEmail(email: any, referralCode: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`sendReferralCodeOnEmail?email=${email}&referralCode=${referralCode}`, { headers: header });
  }

  async getCustomEmotes(channelId: any, isApproved = true, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getCustomEmotes?channelId=${channelId}&isApproved=${isApproved}`, { headers: header });

  }

  async getUsersBadgesAndEmotes(userId: any, channelId: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getUsersBadgesAndEmotes?userId=${userId}&channelId=${channelId}`, { headers: header })
  }

  async generateClientToken(screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`generateClientToken`, { headers: header });
  }

  async processTransaction(chargeAmount: any, nonce: any, deviceData: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`processTransaction?chargeAmount=${chargeAmount}&nonce=${nonce}&deviceData=${deviceData}`, '', { headers: header })
  }

  async createCheckoutSession(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`createCheckoutSession`, body, { headers: header });
  }

  // channel detail for particular channel in channel detail
  async getChannelDashboardDetails(channelId: any, screenName: string) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getChannelDashboardDetails?channelId=${channelId}`, { headers: header });
  }

  //past programme of particular channel in  channel detail
  async getPastVideoDetails(channelId: any, screenName: string, pageNo?: any, datetime?: any) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    if (datetime) {
      return this._ApiService.getRequest<BaseResponse>(`getPastVideoDetails?channelId=${channelId}&pageNo=${pageNo}&date=${datetime}`, { headers: header });
    } else {
      return this._ApiService.getRequest<BaseResponse>(`getPastVideoDetails?channelId=${channelId}&pageNo=${pageNo}`, { headers: header });
    }
  }

  //past program total like on particular video channel detail
  async getPastVideoLikesDetails(videoId: any, showId: number, screenName: string) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getVideoLikesUserDetails?videoId=${videoId}&showId=${showId}`, { headers: header });
  }

  //past program total like on particular video channel detail
  async getPastVideoDislikesDetails(videoId: any, showId: number, screenName: string) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getVideoDislikesUserDetails?videoId=${videoId}&showId=${showId}`, { headers: header });
  }

  //past program total like on particular video in channel detail
  async getPastVideoViewDetails(videoId: any, showId: number, screenName: string) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getUserHistory?videoId=${videoId}&showId=${showId}`, { headers: header });
  }


  async getDashboardData(screenName: string) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`dashboard`, { headers: header });
  }

  getMaticWalletBalance(body: any) {
    return this._ApiService.postRequest<any>(`balance`, body, { apiRoot: environment.api.meticApiRoot });
  }

  getExplorerMaticBalance(address: string) {
    return this._ApiService.getRequest(`getTestnetPolygonBalance?walletAddress=${address}`);
  }

  getNativeWalletBalance(address: string) {
    return this._ApiService.getRequest<any>(`account/update/${address}`, { apiRoot: environment.api.explorerApiRoot });
  }

  getAllStake(pageNo, limit) {
    const url: string = pageNo !== null && limit ? `stake/all?pageNumber=${pageNo}&pageLimit=${limit}` : `stake/all`;
    return this._ApiService.getRequest<any>(url, { apiRoot: environment.api.explorerApiRoot });
  }

  getUserViewNotificationHistory(pageNo, limit) {
    return this._ApiService.getRequest<any>(`getUserNotificationViewHistory?page=${pageNo}&limit=${limit}`);
  }

  getStakeDetail(walletAddress: string) {
    return this._ApiService.getRequest<any>(`stake/${walletAddress}`, { apiRoot: environment.api.explorerApiRoot });
  }

  async getVerifyUser(screenName: string) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`getVerifiedUserCount`, { headers: header });

  }
  async getDashboardChartData(screenName: string) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`dashboard/chart/details`, { headers: header });
  }

  async exportData(channelId: any, screenName: string, date?: any) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    if (date) {
      return this._http.get(`${this.apiRoot}/shows/analytics/export?channelId=${channelId}&date=${date}`, { responseType: 'blob', headers: header });

    }
    else {
      return this._http.get(`${this.apiRoot}/shows/analytics/export?channelId=${channelId}`, { responseType: 'blob', headers: header });

    }
  }

  async getChannelListForAdmin(searchType: string, searchText: string, isPublish: boolean, page: number, limit: number, screenName: string) {
    let url = '';
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    
    if(searchType === 'NO_FILTER') {
      url = `channel-list-admin?page=${page}&limit=${limit}&searchType=${searchType}`
    }

    if(searchText) {
      if(searchText.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        searchType = 'EMAIL'
      } else {
        searchType = 'USERNAME'
      }
      url = `channel-list-admin?page=${page}&limit=${limit}&searchType=${searchType}&searchText=${searchText}`
    }

    if(isPublish === true || isPublish === false) {
      url = `${url}&isPublish=${isPublish}`
    }

    return this._ApiService.getRequest<BaseResponse>(url, { headers: header });
  }

  async changeChannelStatus(req: any, screenName: string) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)

    return this._ApiService.postRequest<BaseResponse>(`update-channel-status`, req, { headers: header });
  }

  async getChannelVideos(id: number, pageNo: number, limit: number, searchText: string, screenName: string) {
    const userIpInfo: any = await this.getUserIpInfo()
    let url = `channel-videos/${id}?page=${pageNo}&limit=${limit}`;
    if(searchText) {
      url = `${url}&searchText=${searchText}`
    }
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)

    return this._ApiService.getRequest<BaseResponse>(url, { headers: header });
  }

  async changeVideoStatus(req: any, screenName: string) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)

    return this._ApiService.postRequest<BaseResponse>(`update-video-status`, req, { headers: header });
  }

  async getNotificationVisitCount(pageNo: number, screenName: string) {
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)

    return this._ApiService.getRequest<BaseResponse>(`notification-count?page=${pageNo}&size=10`, { headers: header });
  }

  async getBrowingHistory(fromDate: string, toDate: string, screenName: string) {
    let url = `browsing-history`
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)

    if(fromDate && toDate) {
      url = `${url}?fromDate=${fromDate}&toDate=${toDate}`
    }

    return this._ApiService.getRequest<BaseResponse>(url, { headers: header });
  }

  async downloadBrowingHistory(fromDate: string, toDate: string, screenName: string) {
    let url = `${environment.api.apiRoot}/browsing-history-export`
    const userIpInfo: any = await this.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)

    if(fromDate && toDate) {
      url = `${url}?fromDate=${fromDate}&toDate=${toDate}`
    }

    return this._http.get(url, { responseType: 'blob', headers: header });
  }



  // push org api

  sendPushNotification(payload: {subject: string, message: string, cta: string}) {
    return this._http.post(`${environment.api.pushWebApiRoot}/notification/send-push-notification`, payload);
  }

  getPushNotificationHistory() {
    return this._http.get(`${environment.api.pushWebApiRoot}/notification/show-notification/${environment.pushNotificationAddress}`);
  }
}
