import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { UserWithPrivilege } from '../models/user-modal';

@Injectable({
    providedIn: 'root'
})
export class ActiveUserService {
    userInfo: Subject<UserWithPrivilege>
    user: UserWithPrivilege;
    constructor() {
        this.userInfo = new BehaviorSubject<UserWithPrivilege>(new UserWithPrivilege());
        this.user = new UserWithPrivilege();
    }
    setLogedUser(user: UserWithPrivilege): void {
        this.userInfo.next(user);
        this.user = user;
    }
    getLogedUser(): Observable<UserWithPrivilege> {
        return this.userInfo.asObservable();
    }
    getLogedUserWithoutObservable(): UserWithPrivilege {
        return this.user;
    }
}