import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotifierService } from '../helper/notifier.service';
import { SessionService } from '../helper/session.service';
import * as moment from 'moment';
import { CryptoService } from '../helper/crypto.service';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    Authorization: any;
    token: any;
    constructor(
        private readonly _Injector: Injector,
        private readonly _NotifierService: NotifierService,
        private readonly _SessionService: SessionService,
        private readonly _Router: Router,
        private readonly _CryptoService: CryptoService
    ) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url !== 'https://ipinfo.io/json') {
            const _SessionService = this._Injector.get(SessionService);
            const token = _SessionService.getSession('token');
            let user = 'scriptNetwork';
            let nonce = 'ff271e44dd667385581151162f7e71d49e2740f6465f929fd948d12d30fd17bb6a9898b848596960555aa1a020888f7be39b65bad9b82c61d988eb0b0545387a';
            let currentDate = `${moment().format('DD/MM/YYYY HH:mm:ss.SSS')}`
            let rawDigest = String.raw`${currentDate}\n${window.location.origin}\n${user}\n${nonce}\n`
            let digest = this._CryptoService.encryptHmacSHA256(rawDigest);
            if (token != null) {
                const reqCloned = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token || ''}`,
                        AcceptLanguage: 'en',
                        userAuth: `HmacSHA512 ${user}:${nonce}:${digest}`,
                        requestDate: currentDate,
                        url: window.location.origin
                    }
                });
                return next.handle(reqCloned).pipe(
                    catchError((err: HttpErrorResponse) => {
                      if(err && err.status === 401) {
                        this._NotifierService.showError('Token Expired. Please login again', 'Error')
                        this._SessionService.removeSessions();
                        this._Router.navigate(['auth']);
                      }
                      return throwError(err);
                    })
                  );
            } else {
                const reqCloned = req.clone({
                    setHeaders: {
                        AcceptLanguage: 'en',
                        userAuth: `HmacSHA512 ${user}:${nonce}:${digest}`,
                        requestDate: currentDate,
                        url: window.location.origin
                    },
                });
                return next.handle(reqCloned);
            }
        } else {
            return next.handle(req);

        }

    }
}
