import { Injectable, OnInit } from '@angular/core';
import { Resolve } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class LoginComponentResolver implements Resolve<any>{
    constructor(
        private readonly _AuthService: AuthService
    ) { }
    resolve(): Promise<any> {
        return this._AuthService.getCurrentIpInfo().then(data => data);
    }
}