<h1 id="title" class="title">Verify OTP</h1>

<form (ngSubmit)="verifyOtp(otpForm)" #otpForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-otp">Enter OTP:</label>
    <input nbInput
           [(ngModel)]="Otp"
           #otp="ngModel"
           id="input-otp"
           name="otp"
           placeholder="OTP"
           autofocus
           pattern="^[0-9]{6,6}$"
           fullWidth
           required
          >
  </div>
  <ng-container *ngIf="otp.touched">
          
    <p class="caption status-danger" *ngIf="otp.invalid">
      Enter a valid OTP
    </p>
    
  </ng-container>
  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="!otpForm.valid"
          >
    Verify OTP
  </button>
</form>