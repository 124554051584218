import { Pipe, PipeTransform } from '@angular/core';
import { Privilege } from '../../models/user-modal';

@Pipe({
    name: 'appIsPrivilege'
})
export class PrivilegePipe implements PipeTransform {
    transform(value: Privilege[], key: string) {
        return value && value.length ? value.some(val => val.value.indexOf(key) > -1) : null;
    }
}