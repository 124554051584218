<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><span><img class="scriptTvlogo" width="150px"
          src="../../../../assets/logo-beta.svg" style="border-radius: 5px;margin-right: 10px;" alt=""></span></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
  <!-- <ngx-layout-direction-switcher class="direction-switcher"></ngx-layout-direction-switcher> -->
 
</div>

<div class="header-container">
  <!-- <ul style="list-style: none;display: flex;float: right;margin-top: 2px;">
    <li class="nav-item " > <img
       src="../../../../assets/bell.svg" class="cursor-pointer dropdown-toggle"  data-toggle="dropdown"
       style=" margin-top: 10px;" alt="">
        <span
       class="badge bg-warning text-dark">{{unreadNotification}}</span>

     <nb-select  *dropdown  class=" left-menu notification">
       <p class="notification-header">Notifications <span (click)="deleteNotification(0)"> Clear all</span>
       </p>
       <ul class="notification-list">
         <ng-container *ngFor="let main of notificationContainer">
           <p class="mb-0">{{main.date|date:'mediumDate'}}</p>
           <ng-container *ngFor="let noti of main.data">
             <li>
               <div class="d-flex">
                 <img [src]="getNotificationIcon(noti.notificationTypeId)" class="notification-icon"
                   alt="">
                 <img (click)="visitDetails(noti);readNotification(noti.id)"
                   [src]="noti.senderUserProfile?noti.senderUserProfile:'/assets/profile.svg'"
                   class="notification-sender" alt="">
                 <div class="d-block ml-2" style="font-size: 12px;">
                   <div class=" mt-2"> <span (click)="visitDetails(noti);readNotification(noti.id)">
                       {{noti.body}} </span>
                     <span class="notificaiton-icon" containerClass="customClass" [nbPopover]="temp"
                       nbPopoverPlacement="bottom" (click)="openPop($event,pop)" triggers="" >
                        <nb-icon icon="more-horizontal-outline"></nb-icon>
                     </span>
                     <nb-icon *ngIf="!noti.read" icon="radio-button-off-outline" class="notification-dot"></nb-icon>
                     <br> 
                     <span class="text-muted">
                       {{noti.notifDateTime|date:'h:mm:ss a' }}
                     </span>

                   </div>

                   <ng-template #temp>
                     <ul class="pop-notification">
                       <li (click)="readNotification(noti.id)">
                          <nb-icon icon="checkmark" style="color: rgb(255, 186, 12)">Mark as Read  </nb-icon>
                       </li>
                       <li (click)="deleteNotification(noti.id)">
                           <nb-icon icon="close" style="color: rgb(255, 186, 12)">Remove this notification </nb-icon>
                       </li>
                     </ul>

                   </ng-template>
                 </div>
               </div>
             </li>
           </ng-container>
         </ng-container>

       </ul>
     </nb-select>
   </li> 
   </ul> -->
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action> -->
    <!-- <nb-action class="control-item" icon="bell-outline"></nb-action> -->
   
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="userInfo.user.firstName"
        nbContextMenuTag="profileMenu">
      </nb-user>
    </nb-action>
  </nb-actions>

  <div class="control-status-example">

    <!-- <nb-icon icon="log-out-outline" style="cursor: pointer;font-size: 30px;"
      [options]="{ animation: { type: 'pulse' } }" nbTooltip="Logout" nbTooltipStatus="primary"
      nbTooltipPlacement="bottom" (click)="openDialog(logoutDialog)" status="primary"></nb-icon> -->
    <nb-icon icon="log-out-outline" style="cursor: pointer;font-size: 30px;"
      [options]="{ animation: { type: 'pulse' } }" nbTooltip="Logout" nbTooltipStatus="primary"
      nbTooltipPlacement="bottom" (click)="logOut()" status="primary"></nb-icon>
  </div>
</div>

<ng-template #logoutDialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header style="padding-top: 20px;"><span style="font-size: larger;padding: 10px;">Logging out</span> <span
        style="float: right;" (click)="ref.close();">
      </span></nb-card-header>
    <nb-card-body>
      <h6 style="padding:30px;">{{ data }}</h6>
    </nb-card-body>
    <br>
    <nb-card-footer>
      <button style="float: right;" nbButton hero status='primary' (click)="ref.close();logOut()">Yes</button>
      <button style="float: right;margin-right: 10px;" status='secondary' nbButton hero (click)="ref.close()">No</button>
    </nb-card-footer>
  </nb-card>
</ng-template>