import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import urls from '../../apiUrls';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { NbToastrService,NbComponentStatus } from '@nebular/theme';
@Component({
  selector: 'verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {

  constructor(
    private readonly http:HttpClient,
    private readonly router:Router,
    private readonly route:ActivatedRoute,
    private readonly toastrService: NbToastrService
  ) { }
  Otp;
  email;
  ngOnInit() {
    this.route.params.subscribe(params=>{
      this.email=params.email;
    })
  }

  verifyOtp(form){
      this.http.get(urls.verifyOTP+this.email+"&token="+form.value.otp).subscribe((res:any)=>{
       
        this.showToast('success',"Success","Otp Verified")
       localStorage.setItem('token',res.data);
        this.router.navigate(['auth/reset-password',{email:this.email}])
      },error=>{
        console.log(error);
        this.showToast('warning',"Error",error.error.error.message)
      })
  }
  showToast(status: NbComponentStatus,title,message) {
    this.toastrService.show(message, title, { status, limit:1 });
  }
}
