import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { NotifierService } from '../../services/helper/notifier.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss']
})
export class TwoFactorAuthComponent implements OnInit {
  verifyForm: FormGroup;
  verifyFormSubmitted: boolean;
  verifyFormSubmitting: boolean
  key = environment.googleTwoFA.key;
  qr =  environment.googleTwoFA.qr
  constructor(
    private readonly _FormBuilder: FormBuilder,
    private readonly _AuthService: AuthService,
    private readonly _NotifierService: NotifierService,
    private readonly _Router: Router

  ) {
    this.verifyFormSubmitted = false;
    this.verifyFormSubmitting = false;
    this.verifyForm = this._FormBuilder.group({
      pin: ['', [Validators.required]],
      key: ['']
    })
  }

  ngOnInit() {
    this.verifyForm.controls.key.setValue(this.key);

  }
  verifyFormSubmit() {
    this.verifyFormSubmitted = true;
    if (this.verifyForm.valid) {
      this.verifyFormSubmitting = true;
      this._AuthService.verifyQr(this.verifyForm.value).then(data => {
        this.verifyFormSubmitting = false;
        if (data.success) {
          this._NotifierService.showSuccess('user verified', 'Success')
          this.verifyFormSubmitted = false;
          this._Router.navigate([`admin/dashboard`])

        } else {
          this._NotifierService.showError('enter valid code', 'Error');
        }
      })

    }

  }

}
