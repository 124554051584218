import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from '../../services/auth.service';
import { NotifierService } from '../../services/helper/notifier.service';
@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private readonly _AuthService: AuthService,
    private readonly router: Router,
    private readonly _NotifierService: NotifierService
  ) { }
  Email;
  showSpinner = false;
  disabled = false;

  ngOnInit() {
  }
  forgotPass(form) {
    this.showSpinner = true;
    this.disabled = true;
    this._AuthService.forgotPassword({ email: form.value.email }, 'forgot-password').then(data => {
      this.showSpinner = false;
      if (data.isSuccess) {
        this.disabled = false;
        this._NotifierService.showSuccess(data.message);
        this.router.navigate(['auth/login']);
      } else {
        this.disabled = false;
        this._NotifierService.showError(data.message, 'Error');
      }
    })

  }


}
