import { Component } from '@angular/core';

@Component({
    selector: 'app-spinner',
    template: `
    <nb-card accent="danger" size="tiny" 
             nbSpinnerStatus="danger"
             nbSpinnerSize="large"
             nbSpinnerMessage="">      
    </nb-card>
    
    `
})
export class SpinnerComponent {
    constructor() {

    }
}