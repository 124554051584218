import { Injectable } from '@angular/core';
import { NbComponentStatus, NbToastrService } from '@nebular/theme';


@Injectable({
    providedIn: 'root'
})

export class NotifierService {
    constructor(
        private readonly _NbToastrService: NbToastrService
    ) {

    }

    showSuccess(message: any, title?: any) {
        this._NbToastrService.success(message, title);

    }
    showError(message: any, title?: any) {
        this._NbToastrService.danger(message, title);

    }
}