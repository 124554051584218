<h1 id="title" class="title">Forgot Password</h1>
<form (ngSubmit)="forgotPass(forgotPassForm)" #forgotPassForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Enter your email:</label>
    <input nbInput
           [(ngModel)]="Email"
           #email="ngModel"
           id="input-email"
           name="email"
          pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
           placeholder="email"
           autofocus
           fullWidth
           required
          >
  </div>
  <ng-container *ngIf="email.touched">      
    <p class="caption status-danger" *ngIf="email.invalid" style="margin-top:-15px">
      Enter a valid email
    </p> 
  </ng-container>
  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [nbSpinner]="showSpinner" nbSpinnerSize="giant" nbSpinnerStatus="primary"
          [disabled]="!forgotPassForm.valid || disabled"
          >
    Request password
  </button>
</form>
<br/><br/>
<div class="container mt-4">
  <section class="sign-in-or-up" aria-label="Sign in or sign up"  style="display: inline;">
    <p class="text-center"><a class="text-link" routerLink="../login">Back to Log In</a></p>
    
  </section>
</div>

