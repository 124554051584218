import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root'
})
export class CryptoService {
    scriptSecret = 'zL1l33JaQSdhX4BIbA76pYYmS3wpx3RXUgIGNSE+Oyc=';
    constructor(
        private readonly _AuthService: AuthService
    ) {

    }

    encrypt(text: any) {
        return CryptoJS.AES.encrypt(text, this._AuthService.secretKey).toString();
    }
    decrypt(text: any) {
        const bytes = CryptoJS.AES.decrypt(text, this._AuthService.secretKey);
        const decryptText = bytes.toString(CryptoJS.enc.Utf8);
        return decryptText;
    }

    encryptHmacSHA256(text: any) {
        let hash = CryptoJS.HmacSHA512(text, this.scriptSecret);
        const hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
        return hashInBase64
    }


}