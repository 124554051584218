import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from './api/api.service';
import { BaseResponse } from '../models/response-modal';
import { SessionService } from './helper/session.service';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public jwtHelper: JwtHelperService,
    private readonly _ApiService: ApiService,
    private readonly _SessionService: SessionService,
    private readonly _MainService: MainService
  ) { }



  headers = new HttpHeaders({ "Authorization": 'Bearer ' + localStorage.getItem('token') });

  getToken() {
    return localStorage.getItem('token');
  }
  tempToken: any
  secretKey = "Script-Tv app"
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (this.tempToken === undefined) {
      try {

        if (token) {
          // !this.jwtHelper.isTokenExpired(token);
          return true;

        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
    else {
      try {

        return !this.jwtHelper.isTokenExpired(this.tempToken);
      } catch (error) {
        return false;
      }
    }
  }

  async login(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this._MainService.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`adminSignin`, body, {headers: header});
  }

  getCurrentIpInfo(): Promise<any> {
    const token = '9cd8657c8e1f2c'
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json');
    header = header.set("Authorization", "Bearer " + token)
    
    return this._ApiService.getRequest<any>(`json`, { apiRoot: 'https://ipinfo.io', headers: header })
  }

  verifyQr(body: any): Promise<any> {
    return this._ApiService.postRequest<any>(`google/verify-qr`, body, { apiRoot: 'https://dev.chat.pandotest.com/api' });
  }
  async changePwd(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this._MainService.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`changePassword`, body, {headers: header});
  }

  async resetPassword(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this._MainService.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`resetPassword`, body, {headers: header});
  }

  //two factor auth
  async getSecretKey(screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this._MainService.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`superAdminAuth/getSecretKey`);
  }
  async getTotpCode(authSecretKey: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this._MainService.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.getRequest<BaseResponse>(`superAdminAuth/getTotpCode?authSecretKey=${authSecretKey}`, {headers: header});
  }

  getbarCodeUrl(authSecretKey: any, body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`superAdminAuth/barcodeUrl?authSecretKey=${authSecretKey}`, body);
  }

  createQRCode(barCodeData: any, filePath: any, body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`superAdminAuth/createQRcode?barCodeData=${barCodeData}&filePath=${filePath}`, body)
  }

  detectBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
      return `Opera`;
    }
    else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      return 'Chrome';
    }
    else if (navigator.userAgent.indexOf("Safari") !== -1) {
      return 'Safari';
    }
    else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return 'Firefox';
    }
    else if ((navigator.userAgent.indexOf("MSIE") !== -1)) {
      return 'IE';
    }
    else {
      return 'unknown';
    }
  }

  async forgotPassword(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this._MainService.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    return this._ApiService.postRequest<BaseResponse>(`forgotPassword`, body, {headers: header});
  }

  async logOut(body: any, screenName: string): Promise<BaseResponse> {
    const userIpInfo: any = await this._MainService.getUserIpInfo()
    let header = new HttpHeaders();
    header = header.set('ipAddress', userIpInfo.ip)
    header = header.set('latitude', userIpInfo.loc.split(',')[0])
    header = header.set('longitude', userIpInfo.loc.split(',')[1])
    header = header.set('countryName', userIpInfo.country)
    header = header.set('screenName', screenName)
    this._SessionService.removeSession('token');
    this._SessionService.removeSessions();
    return this._ApiService.postRequest<BaseResponse>(`logoutUser`, body, {headers: header});
  }

  // isUserLogin() {
  //   const userInfo = this._UserService.getLogedUserWithoutObservable();
  //   if (userInfo?.user.id) {
  //     return true;
  //   } return false;
  // }
}










