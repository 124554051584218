<div class="container">
  <div id="logo" style="margin-bottom: 0px;">
    <img src="../../../assets/logo-beta.svg" width="180px"
      style="margin: auto;display: block;padding-bottom: 20px;" alt="">
  </div>
  <!-- <h1 id="title" class="title">Login</h1> -->
  <!-- <p class="sub-title">Hello! Log in with your email.</p> -->


  <form [formGroup]="loginForm" (submit)="login()" aria-labelledby="title">
    <div class="form-control-group">
      <label class="label" for="input-email">Email:</label>
      <input nbInput fullWidth formControlName="email" class="mb-1" id="input-email" placeholder="email"
        fieldSize="large" autofocus>
      <span class="text-danger"
        *ngIf="loginForm.get('email')?.invalid && (loginFormSubmitted || loginForm.controls.email.touched)">
        <span *ngIf="loginForm.controls.email.errors?.required">Email is required</span>
        <span *ngIf="loginForm.controls.email.errors?.pattern && !loginForm.controls.email.errors?.required">enter
          valid email</span>
      </span>
    </div>
    <div class="form-control-group" style="margin-bottom: 2px;">
      <label class="label" for="input-password">Password:</label>
      <input nbInput fullWidth required formControlName="password" class="mb-1" [type]="type" id="input-password"
        placeholder="Password" fieldSize="large">
      <nb-icon [icon]="icon" [options]="{ animation: { type: 'pulse' } }"
        style="z-index: 1;position: absolute;margin-top: 10px;margin-left: -30px;color: #fff" (click)="showPassword()">
      </nb-icon>
      <span class="text-danger"
        *ngIf="loginForm.controls.password.errors?.required && (loginFormSubmitted || loginForm.controls.password.touched)">
        Password is required
      </span>
    </div>
    <div class="form-control-group accept-group" style="margin-top: 0px;">
      <!-- <mat-checkbox class="example-margin" (click)="remember=!remember" style="margin-top: 3px;">Remember me
      </mat-checkbox> -->

      <span class="label-with-link" style="float: right;">
        <a class="btn btn-link" [routerLink]="['../forgot-password']">Forgot Password?</a>
      </span>
    </div>
    <button nbButton fullWidth status="primary" size="large" [disabled]="loginForm.invalid || loginFormSubmitting"
      [nbSpinner]="loginFormSubmitting" nbSpinnerSize="giant" nbSpinnerStatus="primary" type="submit">
      <ng-container *ngIf="loginFormSubmitting;else loginFormSubmittingDone">
        Login....
      </ng-container>
      <ng-template #loginFormSubmittingDone>
        Log In

      </ng-template>
    </button>
  </form>
</div>