<form [formGroup]="verifyForm" (submit)="verifyFormSubmit()">
  <div class="container">
    <img [src]="qr" height="100px" alt="">

    <hr>
    <p>After installing Google Authenticator, scan QR code <br> above with the camera of your phone:</p>
    <br>
    <div class="form-control-group">
      <input nbInput type="text" autofocus formControlName="pin" placeholder="Authentication code">
      <span>
        <small class="text-danger"
          *ngIf="!!verifyForm.controls.pin.invalid && (verifyFormSubmitted || !!verifyForm.controls.pin.touched)">Required</small>
      </span>
    </div>
  </div>
  <div class="container">
    <button class="verify-btn" nbButton [nbSpinner]="verifyFormSubmitting" status="primary" [disabled]="verifyFormSubmitting"
    type="submit">
    <ng-container *ngIf="verifyFormSubmitting ; else verifyFormSubmittingDone">
      verifying..
    </ng-container>
    <ng-template #verifyFormSubmittingDone>
      verify
    </ng-template>
  </button>
  <button nbButton status="default" [routerLink]="['/auth/login']">Cancel</button>
    
</div>
</form>