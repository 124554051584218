<h1 id="title" class="title">Change password</h1>

<form (ngSubmit)="resetPass(resetPassForm)" #resetPassForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-password">New Password:</label>
    <input nbInput [type]="typeNew" id="input-password" name="password" class="first" placeholder="New Password"
      #password1="ngModel" [(ngModel)]="password" autofocus
      pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" fullWidth fieldSize="large" required>
    <nb-icon [icon]="iconNew" [options]="{ animation: { type: 'pulse' } }"
      style="z-index: 1;position: absolute;margin-top: 10px;margin-left: -30px;color: blue;" (click)="showNew()">
    </nb-icon>
  </div>
  <ng-container *ngIf="password1.touched">
    <p class="caption status-danger" *ngIf="password1.invalid">
      Password must have minimum eight characters, at least one letter, one number and one special character
    </p>

  </ng-container>
  <div class="form-group">
    <label class="label" for="input-re-password">Confirm Password:</label>
    <input nbInput id="input-re-password" name="confirmPassword" [type]="typeConfirm" class="last"
      placeholder="Confirm Password" fullWidth fieldSize="large" [(ngModel)]="confirmPassword"
      #confirmPassword1="ngModel" required>
    <nb-icon [icon]="iconConfirm" [options]="{ animation: { type: 'pulse' } }"
      style="z-index: 1;position: absolute;margin-top: 10px;margin-left: -30px;color: blue;" (click)="showConfirm()">
    </nb-icon>
  </div>
  <ng-container *ngIf="confirmPassword1.touched">
    <p class="caption status-danger" *ngIf="confirmPassword1.invalid && confirmPassword1.errors?.required">
      Password confirmation is required!
    </p>
    <p class="caption status-danger"
      *ngIf="password1.value != confirmPassword1.value && !confirmPassword1.errors?.required">
      Password does not match the confirm password.
    </p>
  </ng-container>
  <button nbButton status="primary" fullWidth size="large"
    [disabled]="resetPassForm.invalid || password1.invalid || confirmPassword1.invalid || password1.value != confirmPassword1.value">
    Change password
  </button>
</form>