import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { FormGroup } from '@angular/forms';
import { NotifierService } from '../../services/helper/notifier.service';
@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly _ActivatedRoute: ActivatedRoute,
    private readonly _AuthService: AuthService,
    private readonly _NotifierService: NotifierService
  ) { }
  password: any;
  email: any;
  confirmPassword: any;
  ngOnInit() {
    this.email = this._ActivatedRoute.snapshot.queryParams.email
  }
  resetPass(form: FormGroup) {
    // console.log(this.email)
    const modifyValue = {
      ...form.value,
      email: this.email,
      newPassword: form.value.confirmPassword
    }
    this._AuthService.resetPassword(modifyValue, 'reset-password').then((data) => {
      if (data.isSuccess) {
        this._NotifierService.showSuccess(data.message, 'Success');
        this.router.navigate(['auth/login']);
      } else {
        this._NotifierService.showError(data.message, 'Error');
      }
    })
  }
  iconNew = "eye";
  typeNew = "password"
  showNew() {
    if (this.iconNew === "eye") {
      this.typeNew = "text";
      this.iconNew = "eye-off";
    }
    else {
      this.iconNew = "eye";
      this.typeNew = "password";
    }
  }
  iconConfirm = "eye";
  typeConfirm = "password"
  showConfirm() {
    if (this.iconConfirm === "eye") {
      this.typeConfirm = "text";
      this.iconConfirm = "eye-off";
    }
    else {
      this.iconConfirm = "eye";
      this.typeConfirm = "password";
    }
  }

}
