export class BaseResponse {
    timeStamp: string;
    data: any;
    message: any;
    isSuccess: boolean;
    statusCode: number;
    constructor() {
        this.timeStamp = '';
        this.isSuccess = false;
        this.statusCode = 0;
        this.message = '';

    }
}