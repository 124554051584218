import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent
} from '@nebular/auth';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AuthGuard } from './auth.guard';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { TwoFactorAuthComponent } from './components/two-factor-auth/two-factor-auth.component';
import { LoginComponentResolver } from './components/login/login.component.resolver';

const routes: Routes = [
  {
    path: 'admin', canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },

  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,

      },
      {
        path: 'login',
        component: LoginComponent,
        resolve: { info: LoginComponentResolver }
      },
      {
        path: 'two-factor-auth',
        component: TwoFactorAuthComponent
      },
      {
        path: 'verify-otp',
        component: VerifyOtpComponent,
      },

      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
    ],
  },
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  { path: '**', redirectTo: 'admin' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [LoginComponentResolver]
})
export class AppRoutingModule {
}
