import { BaseResponse } from '../models/response-modal';

export class UserModal {
    id: number;
    userName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    status: string;
    password: string;
    confirmPassword: string;
    oldPassword: string;
    email: string;
    roleId: string;
    roleName: string;
    accountLocked: boolean
    profile: UserProfile;
    sta: boolean;
    constructor() {
        this.id = 0;
        this.userName = '';
        this.firstName = '';
        this.middleName = '';
        this.lastName = '';
        this.status = '';
        this.password = '';
        this.confirmPassword = '';
        this.oldPassword = '';
        this.email = '';
        this.roleId = '';
        this.roleName = '';
        this.accountLocked = false;
        this.sta = false;
        this.profile = new UserProfile();

    }
}


export class UserProfile {
    about: string;
    bannerImagefilename: string;
    bio: string;
    description: string;
    filename: string;
    id: number;
    permanentAddressLine1: string;
    permanentAddressLine2: string;
    permanentCity: string;
    permanentCountry: string;
    permanentPincode: string;
    urlBannerImage: string;
    urlProfileImage: string;
    constructor() {
        this.about = '';
        this.bannerImagefilename = '';
        this.bio = '';
        this.description = '';
        this.filename = '';
        this.id = 0;
        this.permanentAddressLine1 = '';
        this.permanentAddressLine2 = '';
        this.permanentCity = '';
        this.permanentCountry = '';
        this.permanentPincode = '';
        this.urlBannerImage = '';
        this.urlProfileImage = '';

    }
}

export class Privilege {
    id: number;
    name: string;
    value: string;
    description: string;
    accessLevel: string
    constructor() {
        this.id = 0;
        this.name = '';
        this.value = '';
        this.description = '';
        this.accessLevel = '';

    }
}

export class UserWithPrivilege {
    user: UserModal;
    userChannelList: any
    privilege: Privilege[]
    constructor() {
        this.user = new UserModal();
        this.privilege = [];
    }
}

export class UserModalWithResponse extends BaseResponse {
    data: UserWithPrivilege;
    constructor() {
        super();
        this.data = new UserWithPrivilege();
    }
}