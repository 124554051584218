import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionService } from '../../services/helper/session.service';
import { NotifierService } from '../../services/helper/notifier.service';
import { LoginModal } from '../../models/admin-users.modal';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  showSpinner = false;
  email: String;
  password: String;
  remember = false;
  loginForm: FormGroup;
  loginFormSubmitted: boolean;
  loginFormSubmitting: boolean;
  LoginModal: LoginModal;
  constructor(
    private readonly router: Router,
    private readonly _AuthService: AuthService,
    private readonly _FormBuilder: FormBuilder,
    private readonly _SessionService: SessionService,
    private readonly _NotifierService: NotifierService,
    private readonly _ActivatedRoute: ActivatedRoute
  ) {
    this.loginFormSubmitted = false;
    this.loginFormSubmitting = false;
    this.LoginModal = new LoginModal();
    this.loginForm = this._FormBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z.]{2,5}$/)]],
      password: ['', [Validators.required]]
    })
  }

  ngOnInit() {
    this._ActivatedRoute.data.subscribe(data => {
      const currentIpInfo = data.info;
      this.LoginModal.browser = this._AuthService.detectBrowser();
      this.LoginModal.country = currentIpInfo.country;
      this.LoginModal.loginLocation = currentIpInfo.region;
      this.LoginModal.loginIp = currentIpInfo.ip;
    })

  }



  login() {
    this.loginFormSubmitted = true;
    if (this.loginForm.valid) {
      this.loginFormSubmitting = true;
      const modifyvalue = {
        ...this.LoginModal,
        ...this.loginForm.value
      }
      modifyvalue.email = this.loginForm.controls.email.value.toLowerCase()
      this._AuthService.login(modifyvalue, 'login').then((res) => {
        this.loginFormSubmitting = false;
        if (res && res.isSuccess) {
          if (this.remember) {
            this._SessionService.setSesssion({ token: res.data.authToken })
            this._SessionService.setSesssion({ email: res.data.email })
            localStorage.setItem('userId',res.data.id)
          } else {
            this._AuthService.tempToken = res.data.authToken;
            this._SessionService.setSesssion({ token: res.data.authToken })
            this._SessionService.setSesssion({ email: res.data.email })
            localStorage.setItem('userId',res.data.id)
          }
          this.loginFormSubmitted = false;
          this.remember = false;
          // this.router.navigate(["/auth/two-factor-auth"]);
          this.router.navigate([`admin/dashboard`])
          this._NotifierService.showSuccess('login successfully','Success');

        } else {
          this.loginFormSubmitted = false;
          this._NotifierService.showError(res.message ? res.message : 'username or password incorrect', 'Login Error');

        }
      })
    }
  }
  icon = "eye";
  type = "password"
  showPassword() {
    if (this.icon === "eye") {
      this.type = "text";
      this.icon = "eye-off";
    }
    else if (this.icon === "eye-off") {
      this.icon = "eye";
      this.type = "password";
    }
  }

}
